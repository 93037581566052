



















































































































































































































































import { Component, Mixins } from 'vue-property-decorator';
import { View } from 'client-website-ts-library/plugins';

@Component
export default class FAQ extends Mixins(View) {
  toggle(id: string) {
    const element = document.getElementById(id);

    if (element) {
      if (element!.className === '') {
        element!.classList.add('hide');
      } else {
        element!.classList.remove('hide');
      }
    }
  }
}
